import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import brandingFeaturedImage from "../../../static/branding/theyyam-trails/theyyam-trails-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/theyyam-trails.scss"
import TheyyamMain from "../../components/branding/theyyam-trails/theyyamMain"
import TheyyamFullwidth from "../../components/branding/theyyam-trails/theyyamFullWidth"
import TheyyamMoreBranding from "../../components/branding/theyyam-trails/theyyamMoreBranding"

export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Theyyam Trails: Cultural Branding Journey | WowMakers"
            description="Embark on a visual odyssey with Theyyam Trails' brand. We showcased Kerala's rich heritage through modern design."
        />
    </>
)

const TheHeritage1866Branding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-theyyam"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <TheyyamMain />
            <TheyyamFullwidth />
            <TheyyamMoreBranding />
        </Layout>
    )
}

export default TheHeritage1866Branding
